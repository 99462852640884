import { AppSettings } from '@/models'

export const environmentDev: AppSettings = {
  api: '/api/internal',
  apiVersion: '/v1',
  apiVersionV2: '/v2',
  refreshTokenTime: 10000,
  staticBusinessHost: 'https://dev.kapusta.by/business',
  msiUrl: 'https://tioauth.raschet.by/oauth/authorize?response_type=code&client_id=zpWZDAMRo10Es0VvJNl7l6SK2pEtwADE&state=9lmNFqosjr&authentication=online_otp&redirect_uri=https%3A%2F%2Fdev.kapusta.by%2Fmsiinfo&scope=msi_contact+msi_id_document+msi_national_id_number+msi_subject+msi_natural_person_tax_info+msi_user_registration_info+msi_session_info&event_id=40',
  msiUpdateUrl: 'https://tioauth.raschet.by/oauth/authorize?response_type=code&client_id=zpWZDAMRo10Es0VvJNl7l6SK2pEtwADE&authentication=online_otp&redirect_uri=https%3A%2F%2Fdev.kapusta.by%2Fmsiinfo&scope=msi_contact+msi_id_document+msi_national_id_number+msi_subject+msi_natural_person_tax_info+msi_user_registration_info+msi_session_info&event_id=40',
  bePaidUrl: 'https://checkout.bepaid.by',
  offer: {
    minAmount: 200, // Минимальная сумма займа в рублях
    maxAmount: 1000, // Максимальная сумма займа в рублях
    minTerm: 10, // Минимальный срок займа в днях
    maxTerm: 60, // Максимальный срок займа в днях
    minPercent: 50, // Минимальный процент по займу
    maxPercent: 4999 // Максимальный процент по займу
  },
  longOffer: {
    minAmount: 100, // Минимальная сумма займа в рублях
    maxAmount: 1000, // Максимальная сумма займа в рублях
    minTermMonths: 2, // Минимальный срок займа в месяцах
    maxTermMonths: 12, // Максимальный срок займа в месяцах
    minPercent: 50, // Минимальный процент по займу
    maxPercent: 10000 // Максимальный процент по займу
  },
  bid: {
    availableCurrencies: ['BYN', 'USD'], // Доступные валюты при создании заявки. Возможные значения: ['BYN', 'USD']
    availablePaymentProcedures: ['monthly', 'quarterly'], // Доступные периоды выплаты при создании заявки. Возможные значения: ['monthly', 'quarterly']
    availableProvisionList: ['bank_guarantee'] // Доступные гарантии при создании заявки. Возможные значения: ['bank_guarantee']
  },
  availableHeaderRotes: ['notifications', 'messages'], // ['Profile', 'Bid', 'Settings', 'Statistics', 'Archive', 'Notifications', 'Messages']
  defaultLanguage: 'ru',
  sideMenuRoutes: ['profile', 'main', 'cards', 'settings', 'archive'] // Доступные роуты в боковом меню. Возможные значения:['profile', 'main', 'cards', 'settings', 'archive']
}
