<template>
  <div class="legislation-page-wrapper">
    <static-pages-header @mobileMenuToggle="mobileMenuToggle"/>
    <brand-cover :is-authorized="isAuthorized" :is-mobile-menu-open="isMobileMenuOpen" />

    <section class="legislation-container">
      <div class="legislation-title">
        {{ $t('message.legislation.title') }}
      </div>
      <div class="legislation-block">
        <div class="legislation-item">
          {{ $t('message.legislation.firstRow') }}
        </div>
        <div class="legislation-item">
          <div>{{ $t('message.legislation.secondRow.firstText') }}</div>
          <a href="https://www.nbrb.by/legislation/onlineborrowingservices?p=obs" target="_blank">{{ $t('message.legislation.secondRow.link') }}</a>
          <div>{{ $t('message.legislation.secondRow.secondText') }}</div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import StaticPagesHeader from '../../components/common/StaticPagesHeader.vue'
import BrandCover from '@/components/common/BrandCover.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'Legislation',
  components: {
    StaticPagesHeader,
    BrandCover
  },
  data() {
    return {
      isMobileMenuOpen: false
    }
  },
  methods: {
    mobileMenuToggle(value) {
      this.isMobileMenuOpen = value
    },
    signUp() {
      this.$router.push({ name: 'msi' })
    }
  },
  computed: {
    ...mapGetters(['profile']),
    isAuthorized() {
      return this.profile?.role
    }
  }
}
</script>

<style lang="scss" scoped>
  .legislation-page-wrapper {
    @include base-page-wrapper;
  }

  .legislation-container {
    @include base-page-container;

    padding: 100px 50px 140px 50px;
    box-sizing: border-box;
    flex-direction: column;
  }

  .legislation-title {
    margin-bottom: 40px;
    font-weight: 700;
    font-size: 40px;
    line-height: 125%;
    color: #181A1B;
  }

  .legislation-block {
    width: 810px;
    max-width: 100%;
    display: flex;
    flex-direction: column;
  }

  .legislation-item {
    width: 100%;
    display: flex;
    flex-direction: column;
    font-weight: 400;
    font-size: 22px;
    line-height: 135%;
    color: #121212;

    a {
      color: #2F80ED;
    }
  }

  .legislation-item:first-of-type {
    margin-bottom: 40px;
  }

  @media screen and (max-width: 820px) {
    .legislation-container {
      padding: 50px 0 140px 0;
    }

    .legislation-title {
      font-size: 30px;
    }

    .legislation-item {
      font-size: 22px;
    }
  }
</style>
