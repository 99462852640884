<template>
  <div class="investing-page-wrapper">
    <static-pages-header @mobileMenuToggle="mobileMenuToggle"/>

    <section class="heading-container" :class="{'heading-container-mobile-menu-open': isMobileMenuOpen}">
      <div class="heading-block">
        <div class="heading">
          {{ $t('message.investingPage.heading.title') }}
        </div>
        <div class="heading-desc">
          {{ $t('message.investingPage.heading.firstDesc') }}
        </div>
        <div class="heading-btn-block">
          <base-button
            :text="$t('message.mainPage.heading.btn')"
            @click="signUp()"
            v-if="!isAuthorized"
          />
        </div>
      </div>
      <img class="heading-img" src="../../../public/custom/images/main-page/phone-heading-img.png" alt="Heading-img">
    </section>

    <section class="advantages-container">
      <div class="advantages-title">{{ $t('message.investingPage.advantages.title') }}</div>
      <div class="advantages-list">
        <advantage-item>
          <template #image>
            <img
                src="../../../public/custom/images/main-page/advantages/7.svg"
                alt="Advantage image"
            />
          </template>
          <template #text>
            {{ $t('message.investingPage.advantages.items.first') }}
          </template>
        </advantage-item>
        <advantage-item>
          <template #image>
            <img
                src="../../../public/custom/images/main-page/advantages/calendar.svg"
                alt="Advantage image"
            />
          </template>
          <template #text>
            {{ $t('message.investingPage.advantages.items.second') }}
          </template>
        </advantage-item>
        <advantage-item>
          <template #image>
            <img
                src="../../../public/custom/images/main-page/advantages/loan-percent.svg"
                alt="Advantage image"
            />
          </template>
          <template #text>
            {{ $t('message.investingPage.advantages.items.third') }}
          </template>
        </advantage-item>
        <advantage-item>
          <template #image>
            <img
                src="../../../public/custom/images/main-page/advantages/3n.svg"
                alt="Advantage image"
            />
          </template>
          <template #text>
            {{ $t('message.investingPage.advantages.items.seven') }}
          </template>
        </advantage-item>
        <advantage-item>
          <template #image>
            <img
                src="../../../public/custom/images/main-page/advantages/ssl.svg"
                alt="Advantage image"
            />
          </template>
          <template #text>
            {{ $t('message.investingPage.advantages.items.fourth') }}
          </template>
        </advantage-item>
        <advantage-item>
          <template #image>
            <img
                src="../../../public/custom/images/main-page/advantages/rating.svg"
                alt="Advantage image"
            />
          </template>
          <template #text>
            {{ $t('message.investingPage.advantages.items.fifth') }}
          </template>
        </advantage-item>
        <advantage-item>
          <template #image>
            <img
                src="../../../public/custom/images/main-page/advantages/judge.svg"
                alt="Advantage image"
            />
          </template>
          <template #text>
            {{ $t('message.investingPage.advantages.items.sixth') }}
          </template>
        </advantage-item>
      </div>
    </section>

    <section class="hiw-container">
      <div class="hiw-block">
        <div class="hiw-data">
          <div class="hiw-data-title">{{ $t('message.investingPage.hiw.title') }}</div>
          <div class="hiw-item">
            <div class="hiw-item-title">
              <div class="hiw-item-number">1</div>
              <span>{{ $t('message.investingPage.hiw.first.title') }}</span>
            </div>
            <div class="hiw-desc">
              {{ $t('message.investingPage.hiw.first.desc') }}
            </div>
          </div>
          <div class="hiw-item">
            <div class="hiw-item-title">
              <div class="hiw-item-number">2</div>
              <span>{{ $t('message.investingPage.hiw.second.title') }}</span>
            </div>
            <div class="hiw-desc">
              {{ $t('message.investingPage.hiw.second.desc') }}
            </div>
          </div>
          <div class="hiw-item">
            <div class="hiw-item-title">
              <div class="hiw-item-number">3</div>
              <span>{{ $t('message.investingPage.hiw.third.title') }}</span>
            </div>
            <div class="hiw-desc">
              {{ $t('message.investingPage.hiw.third.desc') }}
            </div>
          </div>
        </div>
        <img class="hiw-image" src="../../../public/custom/images/main-page/investing-hiw-image.png" alt="Men image">
      </div>
    </section>

    <section class="memo-container">
      <div class="memo-block">
        <div class="memo-title-block">
          <img src="../../../public/custom/images/main-page/loan-memo-img.png" alt="Person image">
          <div class="memo-title">{{ $t('message.investingPage.memo.title') }}</div>
        </div>
        <div class="memo-data-block">
          <ul class="memo-data-item">
            <li>
              {{ $t('message.investingPage.memo.items.first.begin') }}
              <router-link class="memo-data-item-link" to="msi">{{ $t('message.investingPage.memo.items.first.link') }}</router-link>
              {{ $t('message.investingPage.memo.items.first.end') }}
            </li>
            <li>
              {{ $t('message.investingPage.memo.items.second') }}
            </li>
            <li>
              {{ $t('message.investingPage.memo.items.third.begin') }}
              <b>{{ $t('message.investingPage.memo.items.third.bold') }}</b>
              {{ $t('message.investingPage.memo.items.third.end') }}
            </li>
            <li>
              {{ $t('message.investingPage.memo.items.fourth') }}
            </li>
            <li>
              {{ $t('message.investingPage.memo.items.fifth') }}
            </li>
          </ul>
          <ul class="memo-data-item">
            <li>
              {{ $t('message.investingPage.memo.items.sixth') }}
            </li>
            <li>
              {{ $t('message.investingPage.memo.items.seventh.begin') }}
              <router-link class="memo-data-item-link" to="documents">{{ $t('message.investingPage.memo.items.seventh.link') }}</router-link>
            </li>
            <li>
              {{ $t('message.investingPage.memo.items.eighth') }}
            </li>
            <li>
              {{ $t('message.investingPage.memo.items.ninth.begin') }}
              <router-link class="memo-data-item-link" to="arbitration">{{ $t('message.investingPage.memo.items.ninth.link') }}</router-link>
              {{ $t('message.investingPage.memo.items.ninth.end') }}
            </li>
          </ul>
        </div>
        <div class="memo-data-note">
          {{ $t('message.investingPage.memo.note.begin') }}
          <a class="memo-data-item-link" href="https://creditregister.by/" target="_blank">{{ $t('message.investingPage.memo.note.link') }}</a>
          {{ $t('message.investingPage.memo.note.end') }}
        </div>
      </div>
    </section>

    <section class="sign-up-block" v-if="!isAuthorized">
      <div class="sign-up-title">
        {{ $t('message.investingPage.signUp.title') }}
      </div>
      <div class="sign-up-btn-block">
        <base-button
          :text="$t('message.investingPage.signUp.btn')"
          @click="signUp()"
        />
      </div>
    </section>
  </div>
</template>

<script>
import StaticPagesHeader from '../../components/common/StaticPagesHeader.vue'
import { mapGetters } from 'vuex'
import BaseButton from '../../components/base/BaseButton.vue'
import AdvantageItem from '@/components/common/Advantage/AdvantageItem.vue'

export default {
  name: 'Investing',
  components: {
    StaticPagesHeader,
    BaseButton,
    AdvantageItem
  },
  data() {
    return {
      isMobileMenuOpen: false
    }
  },
  methods: {
    mobileMenuToggle(value) {
      this.isMobileMenuOpen = value
    },
    signUp() {
      this.$router.push({ name: 'msi' })
    }
  },
  computed: {
    ...mapGetters(['profile']),
    isAuthorized() {
      return this.profile?.role
    }
  }
}
</script>

<style lang="scss" scoped>
  .investing-page-wrapper {
    @include base-page-wrapper;
    @include static-heading-container;

    .heading-container {
      margin-bottom: 140px;
      padding-top: 50px;
      background-color: white;
    }

    .mb10 {
      margin-bottom: 10px;
    }

    .heading-desc {
      width: 664px;
    }
  }

  .advantages-container {
    @include base-page-container;

    margin-bottom: 200px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .advantages-list {
    @include advantages-list;
  }

  .advantages-title,
  .sign-up-title,
  .memo-title {
    font-weight: 700;
    font-size: calc(24px + 16 * (100vw / 1920));;
    line-height: 125%;
    color: #121212;
    text-align: center;
    margin-bottom: 50px;
  }

  .memo-title {
    margin-bottom: 0;
  }

  .advantages-block {
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  .hiw-container {
    @include base-page-container;

    padding: 0 202px;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
  }

  .hiw-block {
    width: 100%;
    max-height: 550px;
    margin-bottom: 140px;
    display: flex;
    justify-content: space-between;
  }

  .hiw-data {
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .hiw-item {
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  .hiw-image {
    height: 525px;
  }

  .hiw-item-number {
    height: 37px;
    width: 37px;
    margin-right: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background-color: #41BF7B;
    color: white;
  }

  .hiw-item-title {
    margin-bottom: 5px;
    display: flex;
    align-items: center;
    font-weight: 600;
    font-size: calc(20px + 6 * (100vw / 1920));
    line-height: 35px;
    color: #121212;
  }

  .hiw-desc {
    margin-left: 57px;
    font-weight: 400;
    font-size: calc(16px + 2 * (100vw / 1920));
    line-height: 135%;
    color: #474747;
  }

  .hiw-data-title {
    font-weight: 700;
    font-size: calc(24px + 16 * (100vw / 1920));;
    line-height: 125%;
    color: #121212;
    text-align: start;
  }

  .sign-up-block {
    margin-bottom: 140px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .sign-up-btn-block {
    height: 65px;
    width: 300px;
    max-width: 90%;
  }

  .memo-container {
    @include base-page-container;

    margin-bottom: 140px;
    padding: 90px 200px 90px 150px;
    box-sizing: border-box;
    background: #F8F8F8;
    border-radius: 40px;
  }

  .memo-block {
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  .memo-title-block {
    margin-bottom: 50px;
    width: 100%;
    display: flex;
    align-items: center;

    img {
      margin-right: 30px;
    }
  }

  .memo-data-block {
    margin-bottom: 60px;
    display: flex;
    justify-content: space-between;
  }

  .memo-data-item {
    width: calc(50% - 47px);
    font-weight: 400;
    font-size: 18px;
    line-height: 135%;
    color: #333333;

    li {
      margin-bottom: 10px;
    }
  }

  .memo-data-note {
    width: 100%;
    font-weight: 400;
    font-size: 18px;
    line-height: 135%;
    color: #333333;
  }

  .memo-data-item-link {
    color: #2F80ED;
  }

  @media screen and (max-width: 1770px) {
    .advantages-container {
      width: 1400px;
    }
  }

  @media screen and (max-width: 1750px) {
    .advantages-container {
      margin-bottom: 110px;
    }
  }

  @media screen and (max-width: 1610px) {
    .hiw-container {
      padding: 0 5%;
    }

    .memo-container {
      padding: 90px 100px 90px 90px;
    }
  }

  @media screen and (max-width: 1400px) {

    .memo-data-item {
      font-size: calc(13px + 5 * (100vw / 1920));
    }

    .memo-title {
      font-size: calc(21px + 19 * (100vw / 1920));
    }

    .memo-data-note {
      font-size: calc(13px + 5 * (100vw / 1920));
    }
  }

  @media screen and (max-width: 1150px) {
    .memo-container {
      padding: 4%;
    }

    .heading-block {
      width: 50%;
    }

    .investing-page-wrapper .heading-desc {
      width: 100%;
    }
  }

  @media screen and (max-width: 890px) {
    .heading-block {
      width: 90%;
    }

    .investing-page-wrapper .heading,
    .heading-desc {
      width: 100%;
      text-align: start;
    }
  }

  @media screen and (max-width: 750px) {
    .memo-data-block {
      flex-direction: column;
    }

    .memo-data-item {
      width: 90%;
      margin-bottom: 0;
    }
  }

  @media screen and (max-width: 550px) {
    .heading-img {
      display: none;
    }

    .investing-page-wrapper .heading-container {
      margin-bottom: 70px;
    }
  }

  @media screen and (max-width: 500px) {
    .heading-block {
      width: 95%;
    }

    .memo-data-item {
      padding-left: 5%;
    }

    .hiw-image {
      width: 99%;
      height: 330px;
    }
  }

  @media screen and (max-width: 1300px) {
    .hiw-container {
      padding: 0;
    }

    .hiw-image {
      height: 480px;
    }

    .hiw-data-title {
      margin-bottom: 10px;
    }
  }

  @media screen and (max-width: 1060px) {
    .hiw-image {
      height: 370px;
      object-fit: contain;
    }
  }

  @media screen and (max-width: 890px) {
    .hiw-block {
      max-height: fit-content;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
    }

    .hiw-data {
      width: 100%;
      margin-bottom: 40px;
    }

    .hiw-data-title {
      margin-bottom: 30px;
    }

    .hiw-item {
      margin-bottom: 25px;
    }

    .hiw-item-title {
      flex-direction: column;
      align-items: flex-start;

      span {
        margin-bottom: 8px;
      }
    }

    .hiw-item-number {
      margin-bottom: 10px;
    }

    .hiw-desc {
      margin-left: 0;
    }
  }

  @media screen and (max-width: 670px) {
    .advantages-block {
      justify-content: space-between;
    }

    .advantages-title, .memo-title {
      text-align: start;
    }

    .memo-title {
      font-size: calc(17px + 19 * (100vw / 1920));
    }

    .memo-title-block {
      img {
        height: 70px;
      }
    }
  }

  @media screen and (max-width: 480px) {
    .hiw-image {
      width: 90%;
    }
  }
</style>
