<template>
  <div class="contacts-page-wrapper">
    <static-pages-header @mobileMenuToggle="mobileMenuToggle"/>
    <brand-cover :is-authorized="isAuthorized" :is-mobile-menu-open="isMobileMenuOpen" />

    <section class="contacts-container">
      <div class="contacts-block">
        <div class="contacts-block-title">{{ $t('message.contacts.title') }}</div>
        <div class="contacts">
          <div class="contacts-item">
            <div class="contacts-item-title">
              {{ $t('message.contacts.address.title') }}
            </div>
            <div class="contacts-item-desc">
              {{ $t('message.contacts.address.data') }}
            </div>
          </div>
          <div class="contacts-item">
            <div class="contacts-item-title">
              {{ $t('message.contacts.postalAddress.title') }}
            </div>
            <div class="contacts-item-desc">
              {{ $t('message.contacts.postalAddress.data') }}
            </div>
          </div>
          <div class="contacts-item">
            <div class="contacts-item-title">
              {{ $t('message.contacts.time.title') }}
            </div>
            <div class="contacts-item-desc-first">
              {{ $t('message.contacts.time.week') }}
            </div>
            <div class="contacts-item-desc">
              {{ $t('message.contacts.time.data') }}
            </div>
          </div>
          <div class="contacts-item">
            <div class="contacts-item-title">
              {{ $t('message.contacts.phones.title') }}
            </div>
            <div class="contacts-item-desc-first">
              {{ $t('message.contacts.phones.first') }}
            </div>
            <div class="contacts-item-desc">
              {{ $t('message.contacts.phones.second') }}
            </div>
          </div>
          <div class="contacts-item">
            <div class="contacts-item-title">
              {{ $t('message.contacts.support.title') }}
            </div>
            <a class="contacts-item-desc" :href="`mailto:${$t('message.contacts.support.data')}`">
              {{ $t('message.contacts.support.data') }}
            </a>
          </div>
        </div>
      </div>

      <div class="map-block">
        <iframe src="https://yandex.ru/map-widget/v1/?um=constructor%3A9acbfc31d196c19273036c2594c85892247cd84f4f75843f3bfc39908499b6d8&amp;source=constructor" width="100%" height="100%" frameborder="0"></iframe>
      </div>
    </section>

  </div>
</template>

<script>
import BrandCover from '@/components/common/BrandCover.vue'
import StaticPagesHeader from '../../components/common/StaticPagesHeader.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'Contacts',
  components: {
    StaticPagesHeader,
    BrandCover
  },
  data() {
    return {
      isMobileMenuOpen: false
    }
  },
  methods: {
    mobileMenuToggle(value) {
      this.isMobileMenuOpen = value
    },
    signUp() {
      this.$router.push({ name: 'msi' })
    }
  },
  computed: {
    ...mapGetters(['profile']),
    isAuthorized() {
      return this.profile?.role
    }
  }
}
</script>

<style lang="scss" scoped>
  .contacts-page-wrapper {
    @include base-page-wrapper;
  }

  .contacts-container {
    @include base-page-container;

    padding: 100px 50px 140px 50px;
    box-sizing: border-box;
    justify-content: space-between;
  }

  .contacts-block {
    width: 45%;
    margin-right: 5%;
    display: flex;
    flex-direction: column;
  }

  .map-block {
    width: 50%;
    height: auto;
    border-radius: 20px;

    iframe {
      border-radius: 20px;
    }
  }

  .contacts-block-title {
    margin-bottom: 50px;
    font-weight: 700;
    font-size: 40px;
    line-height: 125%;
    color: #181A1B;
  }

  .contacts {
    display: flex;
    flex-direction: column;
  }

  .contacts-item {
    margin-bottom: 40px;
    display: flex;
    flex-direction: column;
  }

  .contacts-item:last-of-type {
    margin-bottom: 0;
  }

  .contacts-item-title {
    margin-bottom: 10px;
    font-weight: 600;
    font-size: 22px;
    line-height: 130%;
    color: #181A1B;
  }

  .contacts-item-desc {
    font-weight: 400;
    font-size: 18px;
    line-height: 135%;
    color: #3D3F42;
    text-decoration: none;
  }

  .contacts-item-desc-first {
    margin-bottom: 8px;
    font-size: 18px;
    line-height: 135%;
  }

  @media screen and (max-width: 1460px) {
    .contacts-container {
      padding-left: 0;
      padding-right: 0;
    }
  }

  @media screen and (max-width: 1000px) {
    .contacts-container {
      flex-direction: column;
      padding-top: 50px;
    }

    .map-block {
      width: 100%;
      min-height: 500px;

      iframe {
        min-height: 500px;
      }
    }

    .contacts-block {
      margin-bottom: 50px;
    }

    .contacts-block {
      width: 100%;
      margin-right: 0;
    }

    .contacts-block-title {
      margin-bottom: 20px;
    }

    .contacts-item-title {
      margin-bottom: 5px;
    }

    .contacts-item {
      margin-bottom: 15px;
    }
  }
</style>
