<template>
  <footer class="footer">
    <div class="footer-container">
      <header class="flex flex-col gap-4 md:flex-row md:justify-between mb-12">
        <div class="flex flex-col gap-4">
          <img class="w-[150px]" src="../../../public/custom/images/logo.svg" alt="Logo image" width="150">
          <p>{{ $t('message.footer.reliable_partner') }}</p>
        </div>
        <div class="flex gap-4">
          <base-button
            v-if="isAuthorized"
            key="sign-out"
            :text="$t('message.header.signOutBtn')"
            @click="signOut()"
          />
          <template v-else>
            <base-button
              key="sign-up"
              :text="$t('message.header.register')"
              @click="signUp()"
            />
            <base-button
              color="secondary"
              key="sign-in"
              :text="$t('message.header.signInBtn')"
              @click="$router.push({name: 'SignIn'})"
            />
          </template>
        </div>
      </header>
      <div class="flex flex-col gap-8 mb-12">
        <div class="relative inline self-start" v-click-outside="hide">
          <div class="inline-flex gap-4 items-center cursor-pointer" @click="opened = true">
            <div class="text-lg font-semibold">
              {{ $t(`message.header.for_${currentService}`) | lowerCase | uppercaseStart }}
            </div>
            <img
              src="../../../public/custom/images/arrow-down.svg"
              alt="Arrow down image"
            />
          </div>
          <div class="absolute z-10 mt-2 w-full origin-top-right rounded-md bg-white shadow-lg [&_div]:p-4 [&_div]:cursor-pointer" v-show="opened">
            <div class="hover:bg-gray-100" @click="toggleService('individuals')">{{ $t('message.header.for_individuals') | lowerCase | uppercaseStart }}</div>
            <div class="hover:bg-gray-100" @click="toggleService('business')">{{ $t('message.header.for_business') | lowerCase | uppercaseStart }}</div>
          </div>
        </div>
        <div class="grid grid-cols-2 md:grid-cols-3 gap-3">
          <div class="flex flex-col gap-6">
            <router-link class="footer-link" to="/about">{{ $t('message.footer.links.about') }}</router-link>
            <router-link to="/contacts" v-slot="{ href }">
              <router-link :to="href" v-if="isIndividuals">{{ $t('message.footer.links.contacts') }}</router-link>
              <a :href="`${settings.staticBusinessHost}/contacts`" v-else>{{ $t('message.footer.links.contacts') }}</a>
            </router-link>
            <router-link to="/news" v-slot="{ href }">
              <router-link :to="href" v-if="isIndividuals">{{ $t('message.footer.links.news') }}</router-link>
              <a :href="`${settings.staticBusinessHost}/news`" v-else>{{ $t('message.footer.links.news') }}</a>
            </router-link>
          </div>
          <div class="flex flex-col gap-6">
            <router-link to="/documents" v-slot="{ href }">
              <router-link :to="href" v-if="isIndividuals">{{ $t('message.footer.links.docs') }}</router-link>
              <a :href="`${settings.staticBusinessHost}/documents`" v-else>{{ $t('message.footer.links.docs') }}</a>
            </router-link>
            <router-link class="footer-link" to="/legislation">{{ $t('message.footer.links.legal') }}</router-link>
            <router-link class="footer-link" to="/arbitration">{{ $t('message.footer.links.justice') }}</router-link>
            <router-link to="/questions" v-slot="{ href }">
              <router-link :to="href" v-if="isIndividuals">{{ $t('message.footer.links.questions') }}</router-link>
              <a :href="`${settings.staticBusinessHost}/faq`" v-else>{{ $t('message.footer.links.questions') }}</a>
            </router-link>
          </div>
          <div class="social-block col-span-2 mt-4 md:mt-0 md:col-span-1 justify-self-start md:justify-self-end">
              <div class="flex gap-4">
                <a class="grid place-items-center w-13 h-13 bg-white p-4 rounded-full" :href="link.href" v-for="link of socialsList" :key="link.iconName" target="_blank">
                  <img class="social-link-img" :src="require(`../../../public/custom/images/social/${link.iconName}.svg`)" alt="Social icon">
                </a>
              </div>
          </div>
        </div>
      </div>
      <div class="text-[#0000008F] text-xs">
        <i18n path="message.footer.legal.company.text" :tag="false">
          <template #link>
            <a href="/business/documents/registryInclude.pdf" target="_blank" class="text-[#41bf7b]">{{ $t('message.footer.legal.company.link') }}</a>
          </template>
        </i18n>
      </div>
    </div>
  </footer>
</template>

<script>
import { mapGetters } from 'vuex'
import ClickOutside from 'vue-click-outside'
import { settings } from '@/services/Settings'

export default {
  name: 'Footer',
  directives: {
    ClickOutside
  },
  data() {
    return {
      currentService: 'individuals',
      socialsList: {
        telegram: {
          href: 'https://t.me/kapusta_official',
          iconName: 'black-tg'
        },
        instagram: {
          href: 'https://www.instagram.com/kapusta.by/?igsh=MThvb3FvcGNieWxraw%3D%3D&utm_source=qr#',
          iconName: 'black-instagram'
        },
        tiktok: {
          href: 'https://www.tiktok.com/@kapustaby?_t=8jK3Puqw1eX&_r=1',
          iconName: 'black-tiktok'
        },
        viber: {
          href: 'https://msng.link/o?375259080808=vi',
          iconName: 'black-viber'
        }
      },
      opened: false
    }
  },
  computed: {
    ...mapGetters(['profile']),
    isAuthorized() {
      return this.profile?.role
    },
    isIndividuals() {
      return this.currentService === 'individuals'
    },
    settings() {
      return settings
    }
  },
  methods: {
    toggleService(serviceName) {
      if (this.currentService === serviceName) {
        return
      }
      this.currentService = serviceName
      this.opened = false
    },
    hide() {
      this.opened = false
    },
    signUp() {
      this.$router.push({ name: 'msi' })
    }
  }
}
</script>

<style lang="scss" scoped>
  .footer {
    width: 100%;
    padding: 60px 0 32px 0;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: $header-bgc;
  }

  .footer-container {
    @include base-page-container;

    flex-direction: column;
  }

</style>
