
export default {
  name: 'BrandCover',
  props: {
    isMobileMenuOpen: {
      type: Boolean,
      default: false
    },
    isAuthorized: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    signUp() {
      this.$router.push({ name: 'msi' })
    }
  }
}
