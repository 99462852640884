import axios from './axios'
import { Claim, LegalAction, PagedResult, TradeByAgreement } from '@/models'
import { Trade } from '@/models/trade.class'

export default class TradeService {
  static getTrades(params: Record<string, string | number | string[]>): Promise<PagedResult<Trade>> {
    return axios.get('/trade/', { params })
  }

  static getTrade(id: number): Promise<Trade> {
    return axios.get(`/trade/${id}/`)
  }

  static requestSign(id: number): Promise<{ uuid: string }> {
    return axios.put(`/trade/${id}/sign/request/`, {})
  }

  static signRequestCheckCode(id: number, code: number, uuid: string): Promise<void> {
    return axios.put(`/trade/${id}/sign/check-code/`, { code, uuid })
  }

  static postClaim(id: number): Promise<Claim> {
    return axios.post(`/trade/${id}/post_claim/`, {})
  }

  static claimSignRequest(id: number): Promise<{uuid: string}> {
    return axios.put(`/trade/claim/${id}/sign/request/`, {})
  }

  static claimSignCheckCode(id: number, uuid: string, code: string): Promise<{uuid: string}> {
    return axios.put(`/trade/claim/${id}/sign/check-code/`, { uuid, code })
  }

  static getClaimDocument(id: number): Promise<Blob> { return axios.get(`/trade/claim/${id}/document/`, { responseType: 'blob' }) }

  static postPostLegalAction(id: number): Promise<Trade> {
    return axios.post(`/trade/${id}/post_legal_action/`, {})
  }

  static checkMaxPenalty(id: number): Promise<boolean> {
    return axios.get(`/trade/${id}/legal-action/max-penalty-claim-exists/`, {})
  }

  static legalActionSignRequest(id: number): Promise<{uuid: string}> {
    return axios.put(`/trade/legal_action/${id}/sign/request/`, {})
  }

  static legalActionSignCheckCode(id: number, payload: FormData): Promise<{uuid: string}> {
    const config = {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    }
    return axios.put(`/trade/legal_action/${id}/sign/check-code/`, payload, config)
  }

  static getLegalActionDocument(id: number): Promise<Blob> {
    return axios.get(`/trade/legal_action/${id}/document/`, { responseType: 'blob' })
  }

  static getLegalAction(id): Promise<LegalAction> {
    return axios.get(`/legal_action/${id}/`)
  }

  static pay(id: number, payload: {amount: number}): Promise<void> {
    return axios.put(`trade/${id}/pay_transaction/`, payload)
  }

  static getPayInfo(id): Promise<any> {
    return axios.get(`trade/${id}/amount_due/`)
  }

  static getTradeByAgreement(agreement_id: number): Promise<TradeByAgreement> {
    return axios.get(`/trade-by-agreement/${agreement_id}/`)
  }
}
